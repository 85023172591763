@media (max-width: 1380px) {
  section.static-page-section-content {
    .content-flexbox-wrapper {
      .img-absolute {
        width: 15%;

        &.right {
          z-index: -1;
        }
      }
    }
  }
}

@media (max-width: @screen-sm-max) {
  nav.section-header {
    .header-flexbox {
      .flexbox-account {
        li {
          a {
            img {
              margin-right: 0;
            }

            span {
              display: none;
            }
          }
        }
      }
    }
  }

  section.static-page-section-content {
    .content-flexbox-wrapper {
      .content-flexbox {
        flex-direction: column;
        align-items: center;

        .flexbox-image {
          margin-top: 40px;
          width: 45%;
          order: 2;
        }

        .flexbox-description {
          width: 90%;
          order: 1;
        }
      }

      &:nth-child(odd) {
        .content-flexbox {
          flex-direction: column;
          align-items: center;
        }
      }
    }

    .content-flexbox-cta {
      text-align: center;

      h3 {
        font-size: 22px;
      }
    }

    .ck-editor-text {
      h1,
      h2 {
        strong {
          color: #000;
          font-size: 18px;
        }
      }
    }

    .flexbox-cta {
      .cta-inner {
        padding: 0;
        text-align: center;

        .img-absolute {
          opacity: 0.08;

          &.right {
            right: 20px;
            top: -60px;
          }

          &.left {
            left: 20px;
            top: 60px;
          }
        }

        h2 {
          font-size: 36px;
        }
      }
    }
  }

  section.section-offer {
    &.offer-tiles {
      .offer-flexbox {
        .flexbox-service {
          padding-top: 40%;
          width: 48%;
          margin-top: 20px;
        }
      }
    }
  }

  section.section-steps {
    .step-flexbox {
      flex-direction: column;
      align-items: center;
      width: 100%;

      .flexbox-image {
        width: 50%;
      }

      .flexbox-description {
        width: 100%;
        text-align: center;
        margin-top: 20px;
      }
    }
  }

  div.section-footer {
    .footer-flexbox {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .footer-credits {
      text-align: center;
      justify-content: center;
    }

    .alert {
      text-align: center;
    }
  }
}

@media (max-width: @screen-xs-max) {
  nav.section-header {
    .header-flexbox {
      .nav-button {
        display: flex;
        margin-right: -10px;
      }

      .flexbox-nav-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        background: #fff;
        z-index: 555;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;

        .nav-button-close {
          display: block;
        }

        .flexbox-nav-inner {
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-height: 100%;
          padding: 50px;
          margin-right: 0;

          .flexbox-nav {
            flex-direction: column;

            li {
              margin-right: 0px !important;

              a {
                color: #000;
                font-size: 17px;
                padding: 20px !important;
                display: block;
              }

              &.highlight {
                a {
                  padding: 0;
                  background: none;
                }
              }
            }
          }
        }

        &.active {
          opacity: 1;
          visibility: visible;
        }
      }

      .flexbox-account {
        padding-top: 12px;

        li {
          margin-right: 20px;

          a {
            span {
              display: none;
            }

            img {
              display: block;
            }
          }

          &:nth-last-child(2) {
            margin-right: 20px;
          }
        }
      }
    }
  }

  section.section-main-carousel {
    .main-carousel-content {
      h1 {
        font-size: 40px;
      }
    }
  }

  section.section-steps {
    .step-flexbox {
      .flexbox-image {
        width: 100%;
        max-width: 200px;
      }

      .flexbox-description {
        width: 100%;
        max-width: 400px;
        text-align: center;
        margin-top: 20px;
      }

      &:nth-child(even) {
        flex-direction: column;

        .flexbox-description {
          text-align: center;
        }
      }
    }
  }

  section.section-cta {
    p {
      font-size: 30px;
    }
  }

  section.section-offer {
    &.offer-tiles {
      .offer-flexbox {
        .flexbox-service {
          width: 90%;

          img {
            width: 100%;
          }
        }
      }
    }

    .section-heading {
      h2 {
        font-size: 30px;
      }
    }

    .offer-flexbox {
      flex-direction: column;
      align-items: center;

      .flexbox-service {
        width: 100%;
        max-width: 400px;
      }
    }
  }

  section.pozostala-oferta-section-content {
    .content-flexbox-wrapper {
      .content-flexbox {
        flex-direction: column;

        .flexbox-image {
          width: 100%;
          max-width: 250px;
        }

        .flexbox-description {
          width: 100%;
          max-width: 450px;
          text-align: center;
          margin-top: 30px;
        }
      }

      &:nth-child(odd) {
        .content-flexbox {
          flex-direction: column;
        }
      }
    }
  }

  section.zamow-diete-szczegoly-section-content {
    .date-wrapper {
      flex-direction: column;

      .date-input {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          margin: 0 0 20px 0;
        }

        &:first-child {
          margin: 0 0 20px 0;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  section.zamow-diete-szczegoly-section-content {
    padding: 20px 0 70px;

    .content-heading {
      .heading-image {
        width: 70%;
      }
    }
  }
}
