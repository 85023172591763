@import url("https://fonts.googleapis.com/css?family=Fira+Sans:300,400,500,700,800&subset=latin-ext");
@import url("https://fonts.googleapis.com/css?family=Pacifico&subset=latin-ext");

body {
  min-height: 100vh;
  font-family: "Fira Sans", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.no-scroll {
    overflow: hidden;
    max-height: 100vh;
  }

  .btn {
    padding: 15px 35px;
    border-radius: 40px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1.5px;
    transition: all 0.3s;
    white-space: normal;

    &.btn-default {
      background: @color1;
      color: #fff;
      border: 1px solid @color1;

      &:hover {
        background: darken(@color1, 10%);
        border-color: darken(@color1, 10%);
      }
    }

    &.btn-dark {
      background: darken(@color1, 10%);
      color: #fff;
      border: 1px solid darken(@color1, 10%);

      &:hover {
        background: darken(@color1, 20%);
        border-color: darken(@color1, 20%);
      }
    }

    &.btn-small {
      padding: 8px 20px;
    }
  }

  ul,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none !important;
  }

  .ck-editor-text {
    ul,
    ol {
      padding-left: 20px;
    }

    img {
      max-width: 100% !important;
    }

    * {
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .m-t-5 {
    margin-top: 5px;
  }
  .m-t-10 {
    margin-top: 10px;
  }
  .m-t-15 {
    margin-top: 15px;
  }
  .m-t-20 {
    margin-top: 20px;
  }
  .m-t-25 {
    margin-top: 25px;
  }
  .m-t-30 {
    margin-top: 30px;
  }
  .m-b-5 {
    margin-bottom: 5px;
  }
  .m-b-10 {
    margin-bottom: 10px;
  }
  .m-b-15 {
    margin-bottom: 15px;
  }
  .m-b-20 {
    margin-bottom: 20px;
  }
  .m-b-25 {
    margin-bottom: 25px;
  }
  .m-b-30 {
    margin-bottom: 30px;
  }
  .p-t-5 {
    padding-top: 5px;
  }
  .p-t-10 {
    padding-top: 10px;
  }
  .p-t-15 {
    padding-top: 15px;
  }
  .p-t-20 {
    padding-top: 20px;
  }
  .p-t-25 {
    padding-top: 25px;
  }
  .p-t-30 {
    padding-top: 30px;
  }
  .p-b-5 {
    padding-bottom: 5px;
  }
  .p-b-10 {
    padding-bottom: 10px;
  }
  .p-b-15 {
    padding-bottom: 15px;
  }
  .p-b-20 {
    padding-bottom: 20px;
  }
  .p-b-25 {
    padding-bottom: 25px;
  }
  .p-b-30 {
    padding-bottom: 30px;
  }
}

.system-alerts {
  width: 100%;
  text-align: center;

  .alert {
    border-radius: 0;
    border: none;
    height: 60px;
    display: flex;
    align-items: center;
    opacity: 0.9;
    transition: all 0.4s;

    .alert-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .img-hidden {
        visibility: hidden;
        opacity: 0;
        height: 18px;
      }

      p {
        color: #fff;
        padding: 0 50px;
        font-family: 15px;
      }

      button {
        color: #fff;
        opacity: 1;
        font-weight: 300;
        float: none;

        img {
          height: 18px;
        }
      }
    }

    &.alert-success {
      background: #76ad21;
    }

    &.alert-info {
      background: #218ead;
    }

    &.alert-warning {
      background: #c52b20;
    }
  }
}

nav.section-header {
  position: relative;

  .header-flexbox {
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .flexbox-logo {
      h1 {
        color: @color1;
        line-height: 30px;
        letter-spacing: 2.8px;
        font-size: 22px;
        font-weight: 700;
        font-family: "Pacifico", cursive;
      }

      p {
        font-size: 13px;
        color: #666;
        margin-top: 4px;
      }

      img {
        height: 50px;
      }
    }

    .flexbox-nav-wrapper {
      .nav-button-close {
        position: absolute;
        display: none;
        width: 100%;
        top: 20px;
        left: 0;

        .container {
          display: flex;
          justify-content: flex-end;

          &:before,
          &:after {
            content: none;
          }
        }

        button {
          outline: none;
          border: none;
          background: none;
          padding: 15px;
          display: flex;
          justify-content: center;
          align-items: center;

          .button-bar {
            display: block;
            position: absolute;
            width: 30px;
            height: 2px;
            background: #222;

            &:nth-child(1) {
              transform: rotate(45deg);
            }

            &:nth-child(2) {
              transform: rotate(-45deg);
            }
          }
        }
      }

      .flexbox-nav-inner {
        .flexbox-nav {
          display: flex;
          align-items: center;

          li {
            margin-right: 35px;

            a {
              font-size: 14px;
              color: #666;
              font-weight: 400;

              strong {
                color: @color1;
              }
            }

            &:last-child {
              margin-right: 0 !important;
            }
          }

          &.small-margin {
            li {
              margin-right: 20px;
            }
          }
        }
      }
    }

    .flexbox-account {
      display: flex;
      align-items: center;

      li {
        margin-right: 40px;

        a {
          font-size: 14px;
          color: #666;
          display: flex;
          align-items: center;

          img {
            height: 18px;
            margin-right: 8px;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        &:nth-last-child(2) {
          margin-right: 0;
        }
      }
    }

    .flexbox-social-media {
      display: flex;
      align-items: center;

      li {
        margin-right: 15px;

        a {
          img {
            height: 15px;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .nav-button {
      padding: 10px 10px 10px 0;
      border: none;
      background: none;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      outline: none;
      display: none;

      .button-bar {
        height: 2px;
        width: 24px;
        background: @color1;
        border-radius: 10px;
        transition: all 0.4s;

        &:nth-child(2) {
          margin-top: 4px;
          width: 27px;
        }

        &:nth-child(3) {
          margin-top: 4px;
          width: 30px;
        }
      }

      &:hover,
      &:focus {
        .button-bar {
          width: 30px !important;
        }
      }
    }

    .flexbox-user {
      position: absolute;
      top: 12px;
      right: 0;
      border-radius: 0 0 5px 5px;
      font-size: 10px;
      color: #666;
      font-weight: 200;

      a {
        color: #666;
      }
    }
  }

  &.subpage-border {
    &:after {
      content: "";
      width: 100%;
      height: 1px;
      display: block;
      background: #da4453; /* fallback for old browsers */
      background: -webkit-linear-gradient(
        to right,
        #89216b,
        #da4453
      ); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(
        to right,
        #89216b,
        #da4453
      ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
  }
}

section.section-main-carousel {
  // background: url('../img/backgrounds/bread-2178874_1920.jpg') center;
  // background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .main-carousel-cover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #da4453; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #89216b,
      #da4453
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #89216b,
      #da4453
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    opacity: 0.85;
  }

  .main-carousel-content {
    position: relative;
    text-align: center;

    .owl-carousel-slider {
      height: 580px;
    }

    .owl-stage-outer,
    .owl-stage,
    .owl-item {
      height: 100%;
    }

    .item {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .slider-bg {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;

        .slider-cover {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: #da4453; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #89216b,
            #da4453
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #89216b,
            #da4453
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
          opacity: 0.6;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 50% 50%;
        }
      }

      .slider-text {
        transition: opacity 350ms;

        &.test {
          border-radius: 500px;
          width: 500px;
          height: 500px;
          padding: 0 40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: rgba(#fff, 0.7);

          h1 {
            margin-top: 60px;
            font-size: 32px;
            line-height: 42px;
            color: #000;

            strong {
              display: inline-block;
              margin-bottom: 20px;
              font-size: 60px;
              color: #bf1b60;
            }
          }
        }

        &.animation-opacity {
          opacity: 0;
        }

        h1 {
          color: #fff;
          font-size: 40px;
          font-weight: 200;
          line-height: 60px;

          strong {
            font-size: 80px;
          }
        }

        .btn-default {
          margin-top: 40px;
        }
      }
    }
  }
}

section.section-steps {
  padding: 40px 0 70px 0;

  .steps-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0 10%;
  }

  .step-flexbox {
    flex-direction: column;
    align-items: center;
    width: 22%;

    .flexbox-image {
      margin-bottom: 10px;
    }

    .flexbox-description {
      text-align: center;
      width: 100%;
    }

    &:nth-child(even) {
      flex-direction: column;
      align-items: center;

      .flexbox-description {
        text-align: center;
        width: 100%;
      }
    }
  }
}

.step-flexbox {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .flexbox-image {
    width: 30%;

    .service-image-wrapper {
      width: 100%;
      padding-top: 100%;
      position: relative;
      overflow: hidden;

      .service-image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        border-radius: 50%;
        align-items: center;
        overflow: hidden;

        img {
          width: 100%;
        }
      }
    }
  }

  .flexbox-description {
    width: 65%;

    h2 {
      font-size: 32px;
      font-weight: 300;
      color: @color1;
    }

    p {
      margin-top: 10px;
      color: #666;
      font-weight: 300;
    }
  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    .flexbox-description {
      text-align: right;
    }
  }
}

section.section-cta {
  padding: 30px 0;
  background: darken(@color1, 10%);
  text-align: center;

  p {
    color: #fff;
    font-size: 40px;
    font-weight: 300;
  }
}

section.section-offer {
  padding: 70px 0;

  &.bg {
    background-color: #f3f3f3;
  }

  &.offer-tiles {
    padding: 40px 0 70px;

    .offer-flexbox {
      flex-wrap: wrap;

      .flexbox-service {
        position: relative;
        margin-top: 30px;
        overflow: hidden;
        padding-top: 30%;
        width: 31%;

        &:hover {
          img {
            transform: scale(1.05);
          }
        }

        img {
          margin-top: 0;
          transition: transform 350ms ease-in;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
        }

        .btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin-top: 0;
          width: 80%;
        }
      }
    }
  }

  .section-heading {
    text-align: center;

    h2 {
      font-size: 40px;
      color: @color1;
      font-weight: 300;
    }

    p {
      margin-top: 10px;
      color: #666;
      font-weight: 300;
    }
  }

  .offer-flexbox {
    display: flex;
    justify-content: space-between;

    .flexbox-service {
      margin-top: 70px;
      text-align: center;
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h3 {
        font-size: 26px;
        color: @color1;
      }

      img {
        margin-top: 15px;
        width: 100%;
      }

      p {
        margin-top: 15px;
        color: #666;
        font-weight: 300;
      }

      .btn {
        margin-top: 15px;
      }
    }
  }
}

section.oferta-section-content {
  padding: 60px 0 100px 0;

  h2 {
    color: @color1;
    font-size: 45px;
    margin-bottom: 20px;
  }

  .section-offer {
    margin-top: 60px;

    h3 {
      text-align: center;
      margin-bottom: 30px;
    }

    .offer-content {
      padding-top: 20px;

      .offer-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &:before {
          position: absolute;
          z-index: -1;
          content: "";
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 2000px;
          height: 110%;
          min-height: 160px;
          background-color: #f9f9f9;
        }

        .offer-item {
          margin: 5px;
          border: none;
          background-color: transparent;
          padding: 30px 20px;
          font-size: 15px;
          width: 32%;
          background-color: #fff;
          color: #000;
          text-align: center;
          border: 1px solid transparent;
          transition: all 300ms;

          span {
            font-size: 15px;
            margin-top: 80px;
            font-weight: 500;
          }

          &:focus {
            outline: none;
          }

          &:hover {
            border: 1px solid rgba(@color1, 0.7);
          }

          &.active {
            border: 1px solid rgba(@color1, 0.7);

            span {
              font-weight: 600;
            }

            &:hover {
              border: 1px solid rgba(@color1, 0.7);
            }
          }
        }
      }

      .hidden {
        visibility: hidden;
        opacity: 0;
        min-width: 350px;
      }
    }
  }

  .card {
    font-size: 14px;
    line-height: 26px;
    border: none;
    background-color: transparent;
    padding: 20px 0;
    border-radius: 0;
    margin-top: 40px;

    h4 {
      margin-bottom: 30px;
      font-size: 20px;
    }

    p {
      color: #000;
    }
  }

  .section-cta {
    margin-top: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .cta-text {
      width: 70%;

      h2 {
        color: @color1;
        font-size: 45px;
        margin-bottom: 20px;
      }

      p {
        color: #333;
        font-size: 20px;
        margin-left: 40px;
      }
    }

    .cta-links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 30%;

      a {
        color: @color1;
        font-weight: 700;
        font-size: 26px;

        img {
          width: 50px;
          margin-right: 15px;
        }
      }
    }
  }
}

section.o-firmie-section-content {
  padding: 70px 0;
  background: #f9f9f9;
  position: relative;
  overflow: hidden;

  .img-absolute {
    bottom: 0;
    left: 0;
    width: 100%;
    position: absolute;
  }

  .ck-editor-text {
    padding: 30px;
    border-radius: 10px;
    background: rgba(249, 249, 249, 0.9);

    h1,
    h2 {
      color: @color1;
      font-size: 35px;
      text-align: center;
    }

    p {
      color: #333;
      font-size: 18px;
      font-weight: 300;
    }
  }
}

section.pozostala-oferta-section-content,
section.static-page-section-content {
  .content-flexbox-wrapper {
    padding: 60px 0;
    position: relative;

    h1 {
      font-size: 46px;
      text-align: center;
      margin-bottom: 60px;
    }

    .img-absolute {
      position: absolute;
      top: 0;
      width: 18%;

      &.left {
        left: 0;
      }

      &.right {
        right: 0;
        transform: scaleX(-1);
      }
    }

    .content-flexbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;

      .flexbox-image {
        width: 35%;

        img {
          width: 100%;
          border-radius: 7px;
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        }
      }

      .flexbox-description {
        width: 57%;
      }
    }

    &:nth-child(odd) {
      background: #f5f5f5;

      .content-flexbox {
        flex-direction: row;
      }
    }
  }

  .ck-editor-text {
    h1,
    h2 {
      color: @color1;
      font-size: 35px;

      strong {
        color: #000;
        font-size: 28px;
      }
    }

    p {
      color: #333;
      font-size: 18px;
      font-weight: 300;
    }
  }

  .flexbox-image-gallery {
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
      position: relative;
      width: 24%;
      padding-top: 24%;
      border-radius: 7px;
      margin-top: 10px;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 7px;
      }
    }

    .box-hidden {
      opacity: 0;
      visibility: hidden;
      width: 24%;
    }
  }

  .content-flexbox-cta {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.m-t-60 {
      margin-top: 60px;
    }

    h3 {
      color: @color1;
      font-size: 28px;
      line-height: 38px;
      margin-bottom: 10px;
    }

    .social-media-link {
      img {
        height: 40px;
      }
    }
  }

  .flexbox-cta {
    padding: 60px 0;
    overflow: hidden;

    &.m-t-80 {
      margin-top: 80px;
    }

    &.m-t-20 {
      margin-top: 20px;
    }

    &.m-40 {
      margin: 40px 0;
    }

    &.bg-light {
      background: #f5f5f5;
    }

    .cta-inner {
      position: relative;
      text-align: center;
      padding: 0 20%;

      .img-absolute {
        position: absolute;
        width: 280px;
        opacity: 0.12;
        top: -50px;

        &.right {
          right: 0;
        }

        &.left {
          left: 0;
        }
      }

      h2 {
        color: @color1;
        font-size: 45px;
        margin-bottom: 20px;
      }

      p {
        color: #333;
        font-size: 20px;
      }

      .btn-default {
        margin-top: 30px;
      }
    }
  }
}

section.zamow-diete-szczegoly-section-content {
  padding: 70px 0;
  background: lighten(@gray-lighter, 3%);

  .content-heading {
    text-align: center;

    h1 {
      color: @color1;
      font-size: 35px;
    }

    p {
      margin-top: 15px;
      color: #666;
      font-weight: 300;
    }

    .heading-image {
      width: 40%;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }

    .heading-description {
      padding-top: 60px;
    }
  }

  .content-navigation {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    li {
      button {
        margin: 5px 0;
        background: #999;

        &.active {
          background: darken(@color1, 10%);
          border-color: darken(@color1, 10%);
          color: #fff !important;
        }
      }
    }
  }

  .date-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .date-input {
      position: relative;

      span {
        font-size: 20px;
        font-weight: 700;
        color: @color1;
        margin-right: 20px;
      }

      input {
        width: 160px;
        height: 80px;
        padding: 0 12px 0 20px;
        text-align: center;
        outline: none;
        border-radius: 10px;
        border: 1px solid @color1;
        font-size: 16px;
        font-weight: 700;
        color: @color-text;
      }

      input::-webkit-inner-spin-button,
      input::-webkit-outer-spin-button {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
      }

      input {
        -moz-appearance: textfield;
      }

      &:first-child {
        margin-right: 40px;
      }
    }
  }

  .weekend-option {
    display: flex;
    align-items: center;
    justify-content: center;

    input.apple-switch {
      position: relative;
      -webkit-appearance: none;
      outline: none;
      width: 50px;
      height: 30px;
      margin: 0 20px;
      background-color: #fff;
      border: 1px solid #d9dadc;
      border-radius: 50px;
      box-shadow: inset -20px 0 0 0 @gray-lighter;
      cursor: pointer;
      transition: all 0.3s;

      &:after {
        content: "";
        position: absolute;
        top: 1px;
        left: 1px;
        background: transparent;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
        transition: all 0.3s;
      }

      &:checked {
        box-shadow: inset 20px 0 0 0 @color1;
        border-color: @color1;
        transition: all 0.3s;

        &:after {
          left: 20px;
          box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
        }
      }
    }

    p {
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  .warning {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid @color1;
    text-align: center;

    p {
      color: @color1;
    }
  }

  .details {
    margin-top: 30px;
    font-size: 16px;
    color: @color-text;
    line-height: 50px;
    text-align: center;

    strong {
      font-size: 80px;
      color: @color1;

      span {
        font-size: 60px;
        margin-right: 15px;
        filter: grayscale(50%);
        position: relative;

        &:after {
          content: "";
          position: absolute;
          height: 115%;
          width: 2px;
          background: @color1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(65deg);
        }
      }

      small {
        font-size: 20px;
      }
    }
  }

  .btn {
    margin-top: 30px;
  }
}

div.kontakt-section-content {
  .content-flexbox {
    padding: 50px 0;
    display: flex;
    justify-content: space-between;

    .flexbox-form {
      width: 47%;

      .form-group {
        label {
          font-weight: 400;
          font-size: 12px;
        }
      }

      input[type="text"],
      input[type="email"],
      textarea {
        box-shadow: none;
      }

      textarea {
        min-width: 100%;
        max-width: 100%;
      }

      .checkbox-service {
        margin-top: 15px;
        margin-bottom: 0;
        cursor: pointer;
        width: 100%;
        display: block;

        .service-inner {
          display: flex;
          margin-top: 0;
          font-weight: 400;
          text-align: left;
          font-size: 13px;

          &:before {
            content: "";
            display: block;
            margin-top: 3px;
            margin-right: 12px;
            height: 12px;
            min-width: 12px;
            max-width: 12px;
            background: url("../img/pictures/success.svg") center;
            transition: all 0.4s;
          }
        }

        input {
          visibility: hidden;
          position: absolute;
        }
      }

      .checkbox-service > input:checked + .service-inner {
        &:before {
          background: url("../img/pictures/success-fill.svg") center;
        }
      }

      .info {
        margin-top: 20px;

        p {
          font-size: 12px;
          text-align: left;
        }
      }

      .recaptcha-wrapper {
        margin-top: 20px;
      }

      .btn {
        margin-top: 30px;
      }
    }

    .flexbox-service {
      width: 47%;

      h2 {
        font-weight: 300;
        font-size: 34px;
      }

      address {
        font-size: 16px;
        font-weight: 300;
        margin: 30px 0 0 0;
      }

      .service-card {
        margin-top: 30px;
        padding: 20px;
        background: @gray-lighter;
        border-radius: 6px;

        p {
          font-size: 16px;
          font-weight: 300;

          strong {
            font-size: 19px;
            color: @color1;
          }
        }
      }

      ul {
        margin-top: 30px;

        li {
          margin-bottom: 10px;

          a {
            display: flex;
            align-items: center;
            color: @color1;
            font-size: 19px;
            font-weight: 700;

            img {
              height: 22px;
              margin-right: 10px;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .iframe-wrapper {
    height: 320px;
    width: 100%;

    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}

div.section-footer {
  padding: 25px 0 75px 0;
  background: #2d2a2c;
  position: relative;

  .footer-flexbox {
    display: flex;
    justify-content: space-between;
    position: relative;

    .flexbox-service {
      margin-top: 50px;

      .service-logo {
        h2 {
          color: @color1;
          line-height: 30px;
          letter-spacing: 2.8px;
          font-size: 22px;
          font-weight: 700;
          font-family: "Pacifico", cursive;
        }

        p {
          font-size: 13px;
          color: #666;
          margin-top: 4px;
        }
      }

      ul {
        li {
          margin-top: 10px;

          &.heading {
            margin-top: 0;
            padding-bottom: 10px;
            font-size: 16px;
            color: #ddd;
          }

          a {
            font-size: 12px;
            color: #999;
            transition: all 0.3s;

            b {
              font-size: 16px;
            }

            span {
              font-size: 10px;
            }

            &:hover {
              color: @color1;
            }
          }

          p {
            font-size: 12px;
            color: #999;

            b {
              font-weight: 400;
              font-size: 14px;
            }

            span {
              font-size: 10px;
            }
          }
        }
      }

      .list-social {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        li {
          margin-right: 20px;

          a {
            img {
              height: 20px;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .footer-credits {
    margin-top: 50px;
    padding-top: 50px;
    border-top: 1px solid #3a3738;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    p {
      font-size: 12px;
      color: #999;
      font-weight: 300;

      a {
        color: @color1;
      }
    }
  }

  .alert {
    position: relative;
    margin-top: 50px;
    padding: 50px 0 0 0;
    background: none;
    border: none;
    border-top: 1px solid #3a3738;

    p {
      font-size: 12px;
      color: #999;
      font-weight: 300;

      a {
        color: @color1;
        cursor: pointer;
      }
    }
  }
}
